
import { Options, Vue, mixins } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { EstimationClient } from '@/services/Services';
import EditLayerModal from '../modals/editLayerModal.vue';
import RawMaterialList from './rawMaterialList.vue';
import EditLayerTransportation from './editLayerTransportation.vue';
import LayerWaterFootprint from './layerWaterFootprint.vue';
import LayerLandImpact from './layerLandImpact.vue';
import store from '@/store';
import * as OM from '@/Model';

@Options({
    components: {
        RawMaterialList,
        EditLayerTransportation,
        LayerWaterFootprint,
        LayerLandImpact
    }
})
export default class editLayersComponent extends Vue {

    @Prop() list: OM.LayerComposition[];
    @Prop() estimationIdentifier: string; 
    @Prop() rawMaterials: OM.RawMaterialListVM[]; 
    @Prop() electricityCountries: OM.TextIdentifier[];
    @Prop() transportationCountries: OM.TransportDistanceCalculationVM[];
    @Prop() rawMaterialTransportPhases: { [key: string]: OM.TransportProductionPhase[]; };
    @Prop() waterFootprint: { [key: string]: OM.WaterFootprintPerMaterial[]; };
    @Prop() landImpact: { [key: string]: OM.LandImpactPerMaterial[]; };
    @Prop() suggestedSQM: number;
    @Prop() saveToGarmentConfiguration: boolean;

    created() {
    }

    removeLayer(layerName: string){
        var removeEstimationsGarmentLayers: OM.RemoveEstimantionsGarmentLayersVM = {
            identifier: this.estimationIdentifier, 
            layerName: layerName
        };

        store.state.estimationClient.removeEstimationsGarmentLayers(removeEstimationsGarmentLayers)
        .then( () => {
            this.$emit("init")
        })
    }

    async editLayer(model: OM.LayerComposition, index: number){
        var editModel = new OM.EditEstimationsGarmentLayersVM();
        editModel.index = index;
        
        if(model) {
            editModel.layerName = model.layerName;
            editModel.sqm = model.sqm;
            editModel.grams = model.grams;
            editModel.fabrics = model.fabricCompositions;
        }

        this.$opModal.show(EditLayerModal, {
            estimationIdentifier: this.estimationIdentifier,
            rawMaterials: this.rawMaterials,
            suggestedSQM: this.suggestedSQM,
            isFirstLayer: this.list.length == 0,
            editModel: editModel,
            saveToGarmentConfiguration: this.saveToGarmentConfiguration,
            saveCallback: async (layer: OM.EditEstimationsGarmentLayersVM) => {
                var editEstimationGarmentRis = await store.state.estimationClient.editEstimationsGarmentLayers(layer);
                this.$opModal.closeAll();

                store.state.estimationClient.precompileAllTransportationAndProcessesPhases(this.estimationIdentifier)
                .then(x => {
                    this.$emit("init");
                })
            }
        })
    }

    saveTransportationCallback(editLayerTransportationVM : OM.EditLayerTransportationVM){
        store.state.estimationClient.editRawMaterialTransportation(editLayerTransportationVM)
        .then( x => {
            this.$opModal.closeAll();
            this.$emit("init");
        })
    }

    removeCallback(item: OM.RemoveLayerTransportationVM){
        store.state.estimationClient.removeRawMaterialTransportation(item)
        .then(x => {
            this.$opModal.closeAll();
            this.$emit("init");
        })
    }
}
