import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-367f77cb"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "row mt-3"
}
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = {
  key: 0,
  class: "paddingContainer"
}
const _hoisted_4 = { class: "d-flex align-items-center justify-content-between" }
const _hoisted_5 = { class: "form-group" }
const _hoisted_6 = { class: "d-flex align-items-center" }
const _hoisted_7 = { class: "d-flex align-items-center me-3" }
const _hoisted_8 = { class: "d-flex align-items-center me-3" }
const _hoisted_9 = {
  key: 0,
  class: "my-4"
}
const _hoisted_10 = { class: "d-flex justify-content-end mt-5" }
const _hoisted_11 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_edit_layers_component = _resolveComponent("edit-layers-component")!
  const _component_localized_text = _resolveComponent("localized-text")!
  const _component_raw_material_list = _resolveComponent("raw-material-list")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", null, [
      (_ctx.loaded)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_edit_layers_component, {
                estimationIdentifier: _ctx.estimationIdentifier,
                saveToGarmentConfiguration: _ctx.saveToGarmentConfiguration,
                list: _ctx.layers,
                rawMaterials: _ctx.rawMaterials,
                suggestedSQM: _ctx.estimationBaseInformation.totalSuggestedSQM,
                electricityCountries: _ctx.electricityCountries,
                transportationCountries: _ctx.transportationCountries,
                rawMaterialTransportPhases: _ctx.rawMaterialTransportPhases,
                waterFootprint: _ctx.waterFootprints,
                landImpact: _ctx.landImpacts,
                onInit: _ctx.init
              }, null, 8, ["estimationIdentifier", "saveToGarmentConfiguration", "list", "rawMaterials", "suggestedSQM", "electricityCountries", "transportationCountries", "rawMaterialTransportPhases", "waterFootprint", "landImpact", "onInit"]),
              (_ctx.paddingSection.showPaddingSection)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, [
                      _createElementVNode("div", _hoisted_5, [
                        _createVNode(_component_localized_text, {
                          type: "label",
                          localizedKey: "Do you have a padding for this product?",
                          text: "Do you have a padding for this product?"
                        }),
                        _createElementVNode("div", _hoisted_6, [
                          _createElementVNode("div", _hoisted_7, [
                            _createElementVNode("div", {
                              class: _normalizeClass(["sameAsCheckbox pointer me-2", { active: _ctx.estimationHasPadding }]),
                              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.estimationHasPadding = true))
                            }, null, 2),
                            _createVNode(_component_localized_text, {
                              localizedKey: "Yes",
                              text: "Yes"
                            })
                          ]),
                          _createElementVNode("div", _hoisted_8, [
                            _createElementVNode("div", {
                              class: _normalizeClass(["sameAsCheckbox pointer me-2", { active: !_ctx.estimationHasPadding }]),
                              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.removePadding()))
                            }, null, 2),
                            _createVNode(_component_localized_text, {
                              localizedKey: "No",
                              text: "No"
                            })
                          ])
                        ])
                      ]),
                      (_ctx.estimationHasPadding)
                        ? (_openBlock(), _createElementBlock("button", {
                            key: 0,
                            type: "button",
                            class: "btn btn-tertiary",
                            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.editPadding()))
                          }, [
                            (_ctx.paddingSection.padding)
                              ? (_openBlock(), _createBlock(_component_localized_text, {
                                  key: 0,
                                  localizedKey: "Edit Padding",
                                  text: "Edit Padding"
                                }))
                              : (_openBlock(), _createBlock(_component_localized_text, {
                                  key: 1,
                                  localizedKey: "Add Padding",
                                  text: "Add Padding"
                                }))
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    (_ctx.estimationHasPadding && _ctx.paddingSection.padding)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                          _createVNode(_component_raw_material_list, {
                            item: _ctx.paddingSection.padding
                          }, null, 8, ["item"])
                        ]))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true)
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("button", {
        class: "btn btn-secondary me-3",
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$router.back()))
      }, [
        _createVNode(_component_localized_text, {
          localizedKey: "Previous",
          text: "Previous"
        })
      ]),
      _createElementVNode("button", {
        class: "btn btn-tertiary",
        onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.next())),
        disabled: _ctx.disabled
      }, [
        _createVNode(_component_localized_text, {
          localizedKey: "Continue",
          text: "Continue"
        })
      ], 8, _hoisted_11)
    ])
  ]))
}