
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { ElectricityEmissionClient, EstimationClient, GarmentDefaultDataClient, RawMaterialClient, TransportDistanceCalculationClient, TransportTypeConfigurationClient } from '@/services/Services';
import EditLayersComponent from '../../../components/editLayersComponent.vue';
import RawMaterialList from '../../../components/rawMaterialList.vue';
import EditLayerModal from '../../../modals/editLayerModal.vue';
import store from '@/store';
import * as OM from '@/Model';

@Options({
    components: {
        RawMaterialList,
        EditLayersComponent
    }
})
export default class EditRawMaterials extends Vue {

    @Prop() estimationIdentifier: string;
    @Prop() garmentConfigurationIdentifier: string;
    @Prop() saveToGarmentConfiguration: boolean;

    estimationBaseInformation: OM.EstimationBaseInformationVM = new OM.EstimationBaseInformationVM();

    layers: OM.LayerComposition[] = [];
    estimationHasPadding: boolean = false;
    paddingSection: OM.PaddingInformationVM = new OM.PaddingInformationVM();
    rawMaterials: OM.RawMaterialListVM[] = [];

    electricityCountries: OM.TextIdentifier[] = [];
    rawMaterialTransportPhases: { [key: string]: OM.TransportProductionPhase[]; } = {};
    waterFootprints: { [key: string]: OM.WaterFootprintPerMaterial[]; } = {};
    landImpacts: { [key: string]: OM.LandImpactPerMaterial[]; } = {};
    transportationCountries: OM.TransportDistanceCalculationVM[] = [];
    transportTypes: string[] = [];

    loaded: boolean = false;
    
    created() {
        this.init();
    }

    init(){
        Promise.all([
            store.state.estimationClient.getBaseInformation(this.estimationIdentifier),
            store.state.estimationClient.getEstimationsGarmentLayers(this.estimationIdentifier, null, false),
            store.state.estimationClient.getRawMaterialTransportProductionPhases(this.estimationIdentifier),
            store.state.estimationClient.getWaterFootprints(this.estimationIdentifier),
            store.state.estimationClient.getLandImpacts(this.estimationIdentifier),
            store.state.estimationClient.getPaddingInformations(this.estimationIdentifier),
            RawMaterialClient.getAllRawMaterials(this.garmentConfigurationIdentifier),
            ElectricityEmissionClient.getCountries(true),
            TransportDistanceCalculationClient.getAllConfigurations(),
            TransportTypeConfigurationClient.getAllTransportTypes()
        ])
        .then( xs => {
            this.estimationBaseInformation = xs[0];
            this.layers = xs[1];
            this.rawMaterialTransportPhases = xs[2];
            this.waterFootprints = xs[3];
            this.landImpacts = xs[4];

            this.paddingSection = xs[5];
            if(this.paddingSection.padding)
                this.estimationHasPadding = true;

            this.rawMaterials = xs[6];

            this.electricityCountries = xs[7];
            this.transportationCountries = xs[8];
            this.transportTypes = xs[9].map(x => x.transportType);

            var suggestedSqmFixedNumber = this.estimationBaseInformation.totalSuggestedSQM.toFixed(3);
            this.estimationBaseInformation.totalSuggestedSQM = Number.parseFloat(suggestedSqmFixedNumber);

            this.loaded = true;
        })
    }

    get disabled(){
        if(this.saveToGarmentConfiguration)
            return false;

        return !this.estimationBaseInformation.totalSQM || this.layers.length == 0 ||
            this.layers.filter(x => x.fabricCompositions.length == 0).length > 0 || !this.allTransportSetted ||
            (this.paddingSection.showPaddingSection && this.estimationHasPadding && !this.paddingSection.padding);
    }
    get allTransportSetted() {
        var disabledCount = 0;

        this.layers.forEach(layer => {
            layer.fabricCompositions.forEach(fabric => {
                if(this.rawMaterialTransportPhases[layer.layerName].filter(x => x.rawMaterial.identifier == fabric.rawMaterial.identifier).length == 0)
                    disabledCount = disabledCount + 1;
            });
        });

        return disabledCount == 0;
    }

    async editPadding() {
        var editModel = new OM.EditEstimationsGarmentLayersVM();
        editModel.layerName = this.$localizationService.getLocalizedValue("app_padding", "PADDING & THERMAL INSULATION");
        editModel.sqm = 1;
        
        if(this.paddingSection.padding) {
            editModel.layerName = this.paddingSection.padding.layerName;
            editModel.sqm = this.paddingSection.padding.sqm;
            editModel.grams = this.paddingSection.padding.grams;
            editModel.fabrics = this.paddingSection.padding.fabricCompositions;
        }

        this.$opModal.show(EditLayerModal, {
            estimationIdentifier: this.estimationIdentifier,
            rawMaterials: this.rawMaterials,
            suggestedSQM: 1,
            isFirstLayer: false,
            editModel: editModel,
            saveToGarmentConfiguration: this.saveToGarmentConfiguration,
            isPaddingLayer: true,
            saveCallback: async (layer: OM.EditEstimationsGarmentLayersVM) => {
                layer.isPadding = true;
                var editEstimationGarmentRis = await store.state.estimationClient.editEstimationsGarmentLayers(layer);
                this.$opModal.closeAll();

                store.state.estimationClient.precompileAllTransportationAndProcessesPhases(this.estimationIdentifier)
                .then(x => {
                    this.init();
                })
            }
        })
    }
    removePadding() {
        store.state.estimationClient.removeAllPaddingInformations(this.estimationIdentifier)
        .then(x => {
            this.estimationHasPadding = false;
            this.init();
        })
    }

    next() {
        if(this.saveToGarmentConfiguration){
            var firstProcess = this.$store.state.phases[0];
            this.$router.push("/garmentDefaultData/productionprocess/" + this.estimationIdentifier + "/" + firstProcess.text);

            return;
        }

        (store.state.estimationClient as typeof EstimationClient).getUpdatedProductionPhases(this.estimationIdentifier)
        .then(x => {
            this.$store.state.phases = x;

            (store.state.estimationClient as typeof EstimationClient).initializeAllProcessesByRawMaterialsAndProductionPhase(this.estimationIdentifier, this.$store.state.phases[0].identifier)
            .then(x => {            
                var firstProcess = this.$store.state.phases[0];
                this.$router.push("/calculator/productionprocess/" + this.estimationIdentifier + "/" + firstProcess.text);
            })
        })
    }
}
